import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/AutoDocLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Name`}</h2>
    <p><strong parentName="p">{`step crypto jwe decrypt`}</strong>{` -- verify a JWE and decrypt ciphertext`}</p>
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-raw"
      }}>{`step crypto jwe decrypt
[--key=<file>] [--jwks=<jwks>] [--kid=<kid>]
`}</code></pre>
    <h2>{`Description`}</h2>
    <p><strong parentName="p">{`step crypto jwe decrypt`}</strong>{` verifies a JWE read from STDIN and decrypts the
ciphertext printing it to STDOUT. If verification fails a non-zero failure
code is returned. If verification succeeds the command returns 0.`}</p>
    <p>{`For examples, see `}<strong parentName="p">{`step help crypto jwe`}</strong>{`.`}</p>
    <h2>{`Options`}</h2>
    <p><strong parentName="p">{`--key`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The argument should be the name of a `}<inlineCode parentName="p">{`file`}</inlineCode>{`
containing a private JWK (or a JWK encrypted as a JWE payload) or a PEM encoded
private key (or a private key encrypted using the modes described on RFC 1423 or
with PBES2+PBKDF2 described in RFC 2898).`}</p>
    <p><strong parentName="p">{`--jwks`}</strong>{`=`}<inlineCode parentName="p">{`jwks`}</inlineCode>{`
The JWK Set containing the recipient's private key. The `}<inlineCode parentName="p">{`jwks`}</inlineCode>{` argument should
be the name of a file. The file contents should be a JWK Set or a JWE with a
JWK Set payload. The `}<strong parentName="p">{`--jwks`}</strong>{` flag requires the use of the `}<strong parentName="p">{`--kid`}</strong>{` flag to
specify which key to use.`}</p>
    <p><strong parentName="p">{`--kid`}</strong>{`=`}<inlineCode parentName="p">{`kid`}</inlineCode>{`
The ID of the recipient's private key. `}<inlineCode parentName="p">{`kid`}</inlineCode>{` is a case-sensitive string. When
used with `}<strong parentName="p">{`--key`}</strong>{` the `}<inlineCode parentName="p">{`kid`}</inlineCode>{` value must match the `}<strong parentName="p">{`"kid"`}</strong>{` member of the JWK. When
used with `}<strong parentName="p">{`--jwks`}</strong>{` (a JWK Set) the KID value must match the `}<strong parentName="p">{`"kid"`}</strong>{` member of
one of the JWKs in the JWK Set.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      